import React from "react";
import VerticalColourBar from "./VerticalColourBar";
import ccmi3_logo from "../assets/ccmi-logo-white-text.svg"
import {Link} from "react-router-dom";


const SideNavigation = () => {
    return (
        <div className="side-navigation-container">
            <div className="side-navigation-content-container">
                <Link to="/home">
                    <img src={ccmi3_logo} alt={"CCMI3 Logo"} className="ccmi-logo-sidenav"/>
                </Link>
                <div className="sidenav-links-container">
                    <Link to="/home" className="row sidenav-link">
                        Home
                    </Link>
                    <Link to="/users" className="row sidenav-link">
                        Users
                    </Link>
                    <Link to="/access-codes" className="row sidenav-link">
                        Access Codes
                    </Link>
                    <Link to="/accreditation" className="row sidenav-link">
                        Accreditation
                    </Link>
                    <Link to="/marketing" className="row sidenav-link">
                        Marketing
                    </Link>
                </div>
            </div>
            <VerticalColourBar/>
        </div>
    )
}

export default SideNavigation