import React from "react";
import {Link, Navigate, Route, Routes} from "react-router-dom";
import SideNavigation from "./SideNavigation";

const Home = React.lazy(() => import("../pages/Home"));
const Users = React.lazy(() => import("../pages/Users/Users"));
const UserDetails = React.lazy(() => import("../pages/Users/UserDetails"));
const AccessCodes = React.lazy(() => import("../pages/AccessCodes/AccessCodes"));
const ModifyAccessCodes = React.lazy(() => import("../pages/AccessCodes/ModifyAccessCodes"));
const Accreditation = React.lazy(() => import("../pages/Accreditation/Accreditation"));
const Marketing = React.lazy(() => import("../pages/Marketing/Marketing"));

const AppLayout = () => {

    return (
        <div className="page-container">
            <SideNavigation />
            <div className="content-container">
                <Routes>
                    <Route exact path="/" element={<Navigate to="/home"/>}/>
                    <Route path="/home" element={
                        <React.Suspense fallback={<>...</>}>
                            <Home/>
                        </React.Suspense>
                    }/>
                    <Route path="/users" element={
                        <React.Suspense fallback={<>...</>}>
                            <Users/>
                        </React.Suspense>
                    }/>
                    <Route path="/users/:user_email" element={
                        <React.Suspense fallback={<>...</>}>
                            <UserDetails />
                        </React.Suspense>
                    }/>
                    <Route path="/access-codes" element={
                        <React.Suspense fallback={<>...</>}>
                            <AccessCodes />
                        </React.Suspense>
                    }/>
                    <Route path="/access-codes/:access_code_id" element={
                        <React.Suspense fallback={<>...</>}>
                            <ModifyAccessCodes />
                        </React.Suspense>
                    }/>
                    <Route path="/accreditation" element={
                        <React.Suspense fallback={<>...</>}>
                            <Accreditation />
                        </React.Suspense>
                    }/>
                    <Route path="/marketing" element={
                        <React.Suspense fallback={<>...</>}>
                            <Marketing />
                        </React.Suspense>
                    }/>
                </Routes>
            </div>
        </div>
    )
}

export default AppLayout;