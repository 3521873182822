import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import './utils/Loader';
import AppLayout from "./containers/AppLayout";
import {useEffect} from "react";

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import api from "./api/http"
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App({ signOut, user }) {


    useEffect(() => {
        api.defaults.headers.Authorization = 'Bearer ' + user.signInUserSession.accessToken.jwtToken
    }, [user]);


    return (
        <>
            <div className="user-login-header">
                <div className="user-details">
                    <div className="logged-in-user">Logged In As: {user.username}</div>
                    <button className="button logout link" onClick={signOut}>Sign out</button>
                </div>
            </div>
            <Router>
                <AppLayout/>
            </Router>
        </>

    );
}

export default withAuthenticator(App);
