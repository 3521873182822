
const VerticalColourBar = (props) => {
    return (
        <div className="vertical-colour-bar-container" style={{width: props.width}}>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
            <div className="vertical-colour-bar"/>
        </div>
    )
}

export default VerticalColourBar